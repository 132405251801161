import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { IconDocument, IconPhone, IconEnvelope, IconPhoto } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/link",
  "title": "Link",
  "navTitle": "Link"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Link href="https://hds.hel.fi/" size="M" style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
    Link to HDS
  </Link>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Too many links will clutter a page and make it difficult for users to identify their next steps. This is especially true for inline links, which should be used sparingly.`}</li>
      <li parentName="ul">{`Use links when you want users to:`}
        <ul parentName="li">
          <li parentName="ul">{`Navigate to a different page`}</li>
          <li parentName="ul">{`Navigate to an entirely different site`}</li>
          <li parentName="ul">{`Jump to an element on the same page`}</li>
          <li parentName="ul">{`Link to emails or phone numbers`}</li>
        </ul>
      </li>
      <li parentName="ul">{`HDS Link supports a link with an external icon. Use the `}<inlineCode parentName="li">{`external`}</inlineCode>{` prop when you wish to navigate to an entirely different site.`}</li>
      <li parentName="ul">{`HDS Link supports a link that opens in a new tab. Use the `}<inlineCode parentName="li">{`openInNewTab`}</inlineCode>{` prop when you wish to open the link in a new tab.`}
        <ul parentName="li">
          <li parentName="ul">{`Generally, links should not open in a new tab. However, this can be desirable if the user is in a middle of a process, e.g. filling a form.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`These links are the default link variant. Inline links are used within a sentence or paragraph and are underlined.`}</p>
    <p>{`The underlining helps differentiate them from the text they are placed next to and makes it clear users can interact with them. Inline links should not be used on their own.`}</p>
    <p style={{
      fontSize: '14px'
    }}>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
  aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
  <Link href="https://github.com/City-of-Helsinki/helsinki-design-system" external openInExternalDomainAriaLabel="Opens a different website" mdxType="Link">
    HDS Github
  </Link>
  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
    <hr></hr>
    <h4 {...{
      "id": "standalone",
      "style": {
        "position": "relative"
      }
    }}>{`Standalone`}<a parentName="h4" {...{
        "href": "#standalone",
        "aria-label": "standalone permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Standalone links are used on their own or directly after the content, and they are underlined. If you wish not to have an underline, consider using a button instead.`}</p>
    <p>{`Standalone links should not be used within sentences or paragraphs.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Link href="https://hds.hel.fi/" size="M" style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
    Link to HDS
  </Link>
    </PlaygroundPreview>
    <h4 {...{
      "id": "internal-and-external-links",
      "style": {
        "position": "relative"
      }
    }}>{`Internal and external links`}<a parentName="h4" {...{
        "href": "#internal-and-external-links",
        "aria-label": "internal and external links permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`All HDS links can either be internal or external. Internal links point to the currently active website. External links navigate to an entirely different site. You can also make the link open in a new tab.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
      <Link href="https://hds.hel.fi/" size="M" style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
  Internal link
      </Link>
      <Link href="https://github.com/City-of-Helsinki/helsinki-design-system" size="M" external openInExternalDomainAriaLabel="Opens a different website." style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
  External link
      </Link>
      <Link href="https://github.com/City-of-Helsinki/helsinki-design-system" size="M" external openInNewTab openInExternalDomainAriaLabel="Opens a different website." openInNewTabAriaLabel="Opens in a new tab." style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
  Link that opens in a new tab
      </Link>
    </PlaygroundPreview>
    <h4 {...{
      "id": "links-with-icons",
      "style": {
        "position": "relative"
      }
    }}>{`Links with icons`}<a parentName="h4" {...{
        "href": "#links-with-icons",
        "aria-label": "links with icons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS Links can be paired with an icon. This can be useful if the link has unusual target such as a phone number, an email address (mailto link), a PDF document or a photo.`}</p>
    <p>{`Note! These icons are meant to describe the link target. If you need a directional icon for the link (e.g. an arrow), you should instead use (`}<InternalLink href="/components/buttons#supplementary" mdxType="InternalLink">{`a supplementary button`}</InternalLink>{` with `}<inlineCode parentName="p">{`role="link"`}</inlineCode>{`.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Link iconLeft={<IconDocument size="s" aria-hidden mdxType="IconDocument" />} size="M" href="/#" style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
    Document link
  </Link>
  <Link iconLeft={<IconPhone size="s" aria-hidden mdxType="IconPhone" />} size="M" href="/#" style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
    Phone link
  </Link>
  <Link iconLeft={<IconEnvelope size="s" aria-hidden mdxType="IconEnvelope" />} size="M" href="/#" style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
    Email link
  </Link>
  <Link iconLeft={<IconPhoto size="s" aria-hidden mdxType="IconPhoto" />} size="M" href="/#" style={{
        display: 'block',
        width: 'fit-content'
      }} mdxType="Link">
    Photo link
  </Link>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      